<template>
  <div class="flex-column" style="height: 100vh;">
    <van-nav-bar title="煎配记录" left-text="返回" @click-left="back" left-arrow />
    <van-pull-refresh
      class="flex1"
      style="overflow: auto;"
      v-model="isLoading"
      @refresh="getProject"
    >
      <div v-if="list.length > 0">
        <van-cell v-for="(item,index) in list" :key="index" style="margin-top:10px;">
          <template #title>
            <div>
              <div>
                <van-icon name="clock-o" style="margin-right:5px; " />
                <span style="color:#888;">{{item.tradeTime}}</span>
                <span style="float:right;">{{item.carryTypeName}}</span>
              </div>
              <van-divider style="margin-top:3px;margin-bottom:3px;" />
              <div class="item_container">
                <span style="font-size:16px;">
                  <van-image
                    v-if="item.nameBoilTypeName == '代煎'"
                    :src="require('../../assets/img/jianyao.png')"
                  />
                  <van-image
                    v-if="item.nameBoilTypeName == '代配'"
                    :src="require('../../assets/img/peisong.png')"
                  />
                  <van-image
                    v-if="item.nameBoilTypeName == '其他'"
                    :src="require('../../assets/img/zhongyaofang.png')"
                  />
                  <van-image
                    v-if="item.nameBoilTypeName == '膏方'"
                    :src="require('../../assets/img/gaofang.png')"
                  />
                </span>
                <span style="width:200px;padding:10px;font-size:16px;">
                  {{item.boilTypeName}}
                  <br />
                  <span style="color:#888;font-size:13px;">{{item.hospitalName}}</span>
                </span>
                <span style="float:right;margin-top:10px;font-size:16px;">{{item.stateName}}</span>
              </div>
              <van-divider style="margin-top:3px;margin-bottom:3px;" />
              <div style="font-size:13px;">
                处方{{item.sysRecipeCode}}
                <span style="float:right;">
                  <van-button
                    size="small"
                    style="color:#888;"
                    @click="goDetail(item.sysRecipeCode)"
                  >煎配流程</van-button>
                </span>
              </div>
            </div>
          </template>
        </van-cell>
      </div>
      <div v-if="list.length == 0" class="noData">暂无数据</div>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      show: false,
      title: "标题",
      isLoading: false,
    };
  },
  created() {},
  methods: {
    // 返回
    back() {
      this.$back();
    },
    goDetail(sysRecipeCode) {
      this.$router.push({
        name: "cookdetail",
        query: { way: "系统处方号", queryParam: sysRecipeCode },
      });
    },
    // 获取
    getProject() {
      this.$axios
        .post("/data/recipeHistory", {
          consigneeTel: this.$user.mobile,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code == "ok") {
            this.loading = false;
            this.list = res.data || [];
          } else {
            this.list = [];
            this.$toast.fail("获取失败");
          }
        })
        .catch((err) => {
          this.list = [];
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.getProject();
  },
};
</script>

<style lang="less" scoped>
.one_report {
  margin-bottom: 15px;
}

.form_text {
  margin: 15px 0 30px;
  padding: 0 12px;
}

.form_btn {
  text-align: right;
}

.form_btn button {
  margin-left: 10px;
}

.one_report_data {
  margin-bottom: 20px;
  padding: 0 22px;
  div {
    line-height: 30px;
  }
}
.item_container {
  width: 100%;
  overflow: hidden;
}
.item_container span {
  display: block;
  float: left;
}
</style>